<template>
  <div class="container">
    <!-- 头部 -->
    <Header />

    <div class="news">
      <div class="top_img">
        <img :src="Baseurl+list.banner_image" alt="">
      </div>
      <div class="public_title">
        <div class="pumain">
          <div class="pubbiao">{{list.name}}</div>
          <div class="pubright">
            <img class="dian" src="../../assets/img/dian.png" alt="">
            <span class="risp" v-if="list.father">{{list.father[0].name}}</span>
            <img class="rispi" v-if="list.father" src="../../assets/img/rispi.png" alt="">
            <span class="risp">{{list.name}}</span>
          </div>
        </div>
      </div>
      <div class="news_one">
        <div class="news_cont main">
          <div class="new_left" v-for="(item,index) in toplist.slice(0,1)" :key="index" @click="toNewsdetails(item.id)">
            <div class="new_leimg">
              <img :src="Baseurl+item.image" alt="">
            </div>
            <div class="left_text">
              <div class="time">{{item.f_time.slice(0,10)}}</div>
              <span>{{item.title}}</span>
              <p>{{item.description}}</p>
              <div class="details">查看详情</div>
            </div>
          </div>
          <div class="new_right">
            <div class="newlist" v-for="(item,index) in toplist.slice(1,3)" :key="index" @click="toNewsdetails(item.id)">
              <div class="newlist_text">
                <div class="time">{{item.f_time.slice(0,10)}}</div>
                <span>{{item.title}}</span>
                <p>{{item.description}}</p>
              </div>
              <div class="newlist_img">
                <img :src="Baseurl+item.image" alt="">
              </div>
              <div class="details">查看详情</div>
            </div>
          </div>
        </div>
      </div>
      <div class="news_two">
        <div class="twolist" v-for="(item,index) in morelist" :key="index" @click="toNewsdetails(item.id)">
          <div class="twoleft">
            <img :src="Baseurl+item.image" alt="">
          </div>
          <div class="tworight">
            <p class="time">{{item.f_time.slice(0,10)}}</p>
            <span class="twotit">{{item.title}}</span>
            <p class="text">{{item.description}}</p>
            <div class="details">查看详情</div>
          </div>
        </div>
      </div>
      <!-- <div class="fenye">
            <div class="yema">1</div>
            <div class="yema">2</div>
            <div class="yema">3</div>
            <div class="yema">4</div>
            <div class="yema">5</div>
            <div class="yedian">...</div>
            <div class="yexia">
                <img src="../../assets/img/rispi.png" alt="">
            </div>
        </div> -->
      <div class="brief-anniu clearfix">
        <div class="anan">
          <p class="blo" @click="prev()">上一页</p>
          <p class="blo" :class="page==index+1?'active':''" v-for="(item,index) in yeshu" :key="index" @click="qiehuan(index+1)">{{index+1}}</p>
          <p class="blo" @click="next()">下一页</p>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
  import Header from "../owned/header.vue"
  import Footer from "../owned/footer.vue"

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        id: "",//二级模块ID
        module_id: '',//类型
        page:1,//新闻分页
        list:[],//页面内容
        toplist:[],//

        morelist:[],//下面条的新闻列表
        count:0,//新闻总条数
        yeshu:0,//总共有几页
      };
    },
    inject: ["reload"],
    created() {
      localStorage.setItem("navIndex",1)
      this.id = this.$route.query.id
      this.module_id = this.$route.query.module_id
      if(this.$route.query.page){
        this.page=parseInt(this.$route.query.page)
      }
      this.getinfo()
    },
    methods: {
      getinfo() {
        var that = this;
        that.$axios
          .get(`${this.Baseurl}/search_module?id=${that.id}&module_id=${that.module_id}&page=${that.page}`)
          .then(res => {
            // console.log(res)
            that.list= res.data.data
            console.log(that.list)
            that.toplist = res.data.data.top_news
            that.morelist = res.data.data.news
            that.count = res.data.data.count
            that.yeshu = Math.ceil(that.count/10)
          }).catch(err => console.log(err));
      },
      //去新闻详情页
      toNewsdetails(id) {
        this.$router.push({ name: 'Newsdetails',query:{
            id:id
        }})
      },
      qiehuan(index){
        var that =this
        that.page = parseInt(index)
        that.$router.push({name:"News",query:{
          id:that.id,
          module_id:that.module_id,
          page:that.page
        }})
        this.reload()
      },
      //上一页
      prev(){
        var that =this
        if(parseInt(that.page)>1){
          that.page = parseInt(that.page)-1
          that.$router.push({name:"News",query:{
            id:that.id,
            module_id:that.module_id,
            page:that.page
          }})
        }
        this.reload()
      },
      //下一页
      next(){
        var that =this
        if(parseInt(that.page)<parseInt(that.yeshu)){
          that.page = that.page+1
          that.$router.push({name:"News",query:{
            id:that.id,
            module_id:that.module_id,
            page:that.page
          }})
        }
        this.reload()
      },
    },
    mounted() {

    },

  };
</script>
<style scoped>
  .fenye {
    display: flex;
    align-items: center;
  }

  .top_img {
    width: 100%;
    /* height: 550px; */
  }

  .top_img>img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .news_one {
    width: 100%;
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: #f7f7f7;
  }

  .news .news_cont {
    display: flex;
    justify-content: space-between;
  }

  .news .news_cont .new_left {
    background-color: #fff;
    width: 684px;
    height: 630px;
    cursor: pointer;
  }

  .news .news_cont .new_left .new_leimg {
    /* width: 684px;
  height: 431px; */
    overflow: hidden;
    width: 684px;
    height: 391px;
  }

  .news .news_cont .new_left .new_leimg>img {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
  }

  .news .news_cont .new_left .left_text {
    width: 600px;
    margin: auto;
    margin-top: 20px;
  }

  .news .news_cont .new_left .left_text .time {
    font-size: 12px;
    color: #454545;
  }

  .news .news_cont .new_left .left_text>span {
    height: 50px;
    font-size: 18px;
    color: #454545;
    line-height: 25px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    /*要显示的行数*/
    -webkit-box-orient: vertical;
    margin-top: 10px;
  }

  .news .news_cont .new_left .left_text>p {
    height: 40px;
    font-size: 14px;
    color: #454545;
    line-height: 20px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    /*要显示的行数*/
    -webkit-box-orient: vertical;
    margin-top: 12px;
  }

  .news .news_cont .new_left .left_text .details {
    margin-top: 15px;
  }

  .details {
    width: 108px;
    height: 40px;
    border: 1px solid #D0D0D0;
    text-align: center;
    font-size: 14px;
    color: #454545;
    line-height: 40px;
    cursor: pointer;
    background-color: #f7f7f7;
  }

  .news .news_cont .new_left:hover {
    box-shadow: 0px 0px 12px 4px #bfbfbf;
  }

  .news .news_cont .new_left:hover .new_leimg>img {
    transform: scale(1.12);
  }

  .news .news_cont .new_left:hover .details {
    background-color: #2d64c8;
    border: 1px solid #2d64c8;
    color: #fff;
  }

  .new_right {
    width: 700px;
    display: flex;
    flex-wrap: wrap;
  }

  .new_right .newlist {
    width: 700px;
    height: 301px;
    background: #FFFFFF;
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* margin-left: 33px;
    margin-right: 22px; */
    padding: 30px 22px 0 33px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .new_right .newlist:nth-child(1) {
    margin-top: 0;
  }

  .new_right .newlist .newlist_img {
    width: 302px;
    height: 161px;
    overflow: hidden;
  }

  .new_right .newlist .newlist_img img {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
  }

  .new_right .newlist .newlist_text {
    width: 320px;
  }

  .new_right .newlist .newlist_text .time {
    font-size: 14px;
    color: #454545;
  }

  .new_right .newlist .newlist_text>span {
    height: 50px;
    font-size: 18px;
    color: #454545;
    line-height: 25px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    /*要显示的行数*/
    -webkit-box-orient: vertical;
    margin-top: 15px;
  }

  .new_right .newlist .newlist_text>p {
    font-size: 14px;
    color: #454545;
    line-height: 20px;
    height: 60px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    /*要显示的行数*/
    -webkit-box-orient: vertical;
    margin-top: 15px;
  }

  .new_right .newlist .details {
    position: absolute;
    right: 22px;
    bottom: 32px;
  }

  .new_right .newlist:hover {
    box-shadow: 0px 0px 12px 4px #bfbfbf;
  }

  .new_right .newlist:hover .newlist_img>img {
    transform: scale(1.12);
  }

  .new_right .newlist:hover .details {
    background-color: #2d64c8;
    border: 1px solid #2d64c8;
    color: #fff;
  }

  .news_two {
    width: 1400px;
    margin: auto;
    padding-top: 17px;
  }

  .twolist {
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 40px 0;
    border-bottom: 2px solid #eeeeee;
  }

  .twoleft {
    width: 495px;
    height: 264px;
    overflow: hidden;
  }

  .twoleft>img {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
  }

  .tworight {
    width: 770px;
    margin-left: 47px;
  }

  .tworight .time {
    font-size: 14px;
    color: #454545;
    margin-top: 16px;
  }

  .tworight .twotit {
    font-size: 18px;
    color: #454545;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    margin-top: 20px;
    width: 100%;
  }

  .tworight .text {
    font-size: 14px;
    color: #454545;
    line-height: 20px;
    height: 40px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    /*要显示的行数*/
    -webkit-box-orient: vertical;
    margin-top: 10px;
  }

  .twolist .details {
    margin-top: 40px;
  }

  .twolist:hover .twoleft>img {
    transform: scale(1.03);
  }

  .twolist:hover .details {
    background-color: #2d64c8;
    border: 1px solid #2d64c8;
    color: #fff;
  }


  .brief-anniu {
    width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 54px 0px 48px 0px;
    box-sizing: border-box;
  }

  .brief-anniu>.anan>p {
    background-color: #bdbdbd;
    color: #fff;
    padding: 0 20px;
    line-height: 35px;
    margin: 5px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
  }

  .brief-anniu>.anan>p:hover {
    background-color: #c7161d;
  }

  .brief-anniu>.anan .active {
    background-color: #c7161d;
  }
</style>