<template>
  <div id="app">
    <router-view v-if="isReactive"></router-view>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <!-- <router-view/> -->
  </div>
</template>
<script>
export default {
  // components: {
  //   Swiper
  //     },
  //     name: "Swiper",
  //     data() {
  //         return {
  //             menushow:false,
  //         };
  // },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      token: "",
      routerState: true,
      isReactive: true,
      top_module_list:[],
      footlist:[],
    };
  },
  created() {
    this.goOrther()
  },
  methods: {
    goOrther() {
        var is_mobi =
            navigator.userAgent
                .toLowerCase()
                .match(
                    // /(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                ) != null;
        if (is_mobi) {
            // 如果是移动端就跳转到移动端地址
            let params = new URL(window.location.href).hash;   // new URL 可以得到相关的地址详情
            let baseurl = "https://huizhongtech.net/H_tanMove/index.html" + params;   // 测试接口 
            window.location.href = baseurl;
        } else {
            // 如果是pc 端就不用跳转到其他页面
            // window.location.href = "http://172.20.10.3:8081/"
        }
    },
    reload() {
      this.isReactive = false;
      this.$nextTick(() => {
        this.isReactive = true;
        // console.log("刷新");
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted() {
    // if (this._isMobile()) {
    //   // alert("手机端");
    //   // this.$router.replace('/m');
    //   this.$router.push({name:'Indexm'})
    // } else {
    //   // alert("pc端");
    //   // this.$router.replace('/');
    //   this.$router.push({name:'Index'})
    //   cx();
    //   window.addEventListener("resize", function () {
    //     cx();
    //   });
    // }   
    cx();
    window.addEventListener("resize", function () {
      cx();
    });
    function cx() {
      var Wth = document.documentElement.clientWidth;
      // 1540
      var app =document.querySelector("#app")
      if (Wth < 1450) {
        // console.log(Wth)
        if (navigator.userAgent.indexOf("Firefox") != -1) {
          //判断当前浏览器是否是火狐浏览器
          // app.style.width="1450px"
          // // document.body.style.transform = "scale(" + Wth / 1600 + ")";
          // //   document.body.style.overflow = "hidden";
          // //   document.body.style.transformOrigin = "left top";
          // document.documentElement.style.transform = "scale(" + Wth / 1450 + ")";
          // // document.body.style.overflow = "hidden";
          // document.documentElement.style.transformOrigin = "left top 0px";
          document.documentElement.style.fontSize = (Wth / 1450) * 100 + "px";
        } else {
          document.documentElement.style.zoom = (Wth / 1450) * 100 + "%";
        }
      }else{
        app.style.width="100%"
      }
    }
    
  },
};
</script>

<style lang="less">
/* @import "./assets/css/gstatic.css"; */
@import "../node_modules/swiper/css/swiper.css";
@import "./assets/css/animate.css";
* {
    margin: 0;
    padding: 0;
    font-family: Microsoft YaHei !important;
}

#app {
    position: relative;
}

/* 清除浮动 */
.clearfix:after {
    content: "";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
}

/* 公共的全包 */
.container {
    width: 100%;
    min-width: 1420px;
    overflow: hidden;
}

.main {
    width: 1400px;
    margin: auto;
}

.top_img {
    width: 100% !important;
}

.top_img img {
    width: 100%;
}

.public_title {
    width: 100%;
    /* height: 100px; */
    height: 80px;
    background-color: #fff;
}

.public_title .pumain {
    width: 1400px;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
}

.public_title .pumain .pubbiao {
    /* font-size: 36px; */
    font-size: 28px;
    color: #434343;
}

.pubright {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.pubright .dian {
    width: 18px;
    height: 17px;
    display: block;
    margin-right: 10px;
}

.pubright .risp {
    font-size: 18px;
    color: #434343;
}

.pubright .rispi {
    width: 4px;
    height: 8px;
    display: block;
    margin: 0 10px;
}

.zhanshishow {
    visibility: visible !important;
}

.Tips_elastic {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
}

.Tips {
    position: absolute;
    z-index: 9;
    background-color: #000;
    border-radius: 10px;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Tips p {
    font-size: 18px;
    color: #fff;
}

/* 一个 */
.list_one {
    display: flex;
    width: 100%;
    height: 285px;
    padding: 14px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #F5F5F6;
    margin-bottom: 27px;
    /* margin-left: 20px; */
    border-radius: 8px;
    box-shadow: 0px 0px 10px 1px #f1f1f1;
}

.list_one:hover {
    box-shadow: 0px 0px 10px 1px #dcdcdc;
}

.list_one .top {
    background-color: #fff;
    width: 402px;
    height: 255px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list_one .top .topimg {
    width: 250px;
    height: 250px;
    display: block;
}

.list_one .top .topimg img {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
}

.list_one .right {
    margin-left: 48px;
    width: 800px;
}

.list_one .right .span {
    font-size: 18px;
    color: #434343;
    margin-top: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.list_one .right .text {
    font-size: 14px;
    color: #434343;
    margin-top: 20px;
    line-height: 20px;
    height: 40px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    /*要显示的行数*/
    -webkit-box-orient: vertical;
}

.list_one .right .details {
    width: 108px;
    height: 40px;
    border: 1px solid #D0D0D0;
    text-align: center;
    font-size: 14px;
    color: #454545;
    line-height: 40px;
    cursor: pointer;
    background-color: #f7f7f7;
    margin-top: 40px;
}

.list_one:hover .details {
    background-color: #2d64c8;
    border: 1px solid #2d64c8;
    color: #fff;
}

.list_one:hover .top .topimg img {
    transform: scale(1.1);
}

/* 二个 */
.list_two {
    display: flex;
    width: 680px;
    height: 265px;
    padding: 9px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #F5F5F6;
    margin-bottom: 27px;
    /* margin-left: 20px; */
    border-radius: 8px;
    box-shadow: 0px 0px 10px 1px #f1f1f1;
    margin-left: 40px;
}

.list_two:nth-child(1) {
    margin-left: 0;
}

.list_two:hover {
    box-shadow: 0px 0px 10px 1px #dcdcdc;
}

.list_two .top {
    background-color: #fff;
    width: 346px;
    height: 245px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list_two .top .topimg {
    width: 240px;
    height: 240px;
    display: block;
}

.list_two .top .topimg img {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
}

.list_two .right {
    margin-left: 25px;
    width: 275px;
}

.list_two .right .span {
    font-size: 18px;
    color: #434343;
    margin-top: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.list_two .right .text {
    font-size: 14px;
    color: #434343;
    margin-top: 20px;
    line-height: 20px;
    height: 40px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    /*要显示的行数*/
    -webkit-box-orient: vertical;
}

.list_two .right .details {
    width: 108px;
    height: 40px;
    border: 1px solid #D0D0D0;
    text-align: center;
    font-size: 14px;
    color: #454545;
    line-height: 40px;
    cursor: pointer;
    background-color: #f7f7f7;
    margin-top: 30px;
}

.list_two:hover .details {
    background-color: #2d64c8;
    border: 1px solid #2d64c8;
    color: #fff;
}

.list_two:hover .top .topimg img {
    transform: scale(1.1);
}

/* 三个 */
.list_there {
    width: 412px;
    height: 410px;
    padding: 6px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #F5F5F6;
    margin-bottom: 27px;
    margin-left: 80px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 1px #f1f1f1;
}

.list_there:hover {
    box-shadow: 0px 0px 10px 1px #dcdcdc;
}

.list_there:nth-child(1) {
    margin-left: 0;
}

.list_there .top {
    background-color: #fff;
    width: 100%;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list_there .top .topimg {
    /* width: 249px;
          height: 160px; */
    width: 250px;
    height: 250px;
    display: block;
}

.list_there .top .topimg img {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
}

.list_there .span {
    font-size: 18px;
    color: #434343;
    margin-top: 20px;
    text-align: center;
    padding: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.list_there .details {
    width: 108px;
    height: 40px;
    margin: auto;
    border: 1px solid #D0D0D0;
    text-align: center;
    font-size: 14px;
    color: #454545;
    line-height: 40px;
    cursor: pointer;
    background-color: #f7f7f7;
    margin-top: 15px;
}

.list_there:hover .details {
    background-color: #2d64c8;
    border: 1px solid #2d64c8;
    color: #fff;
}

.list_there:hover .top .topimg img {
    transform: scale(1.1);
}

/* 四个 */
.list_four {
    width: 335px;
    height: 440px;
    padding: 6px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #F5F5F6;
    margin-bottom: 27px;
    margin-left: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 1px #f1f1f1;
}

.list_four:hover {
    box-shadow: 0px 0px 10px 1px #dcdcdc;
}

.list_four:nth-child(4n+1) {
    margin-left: 0;
}

.list_four .top {
    background-color: #fff;
    width: 100%;
    height: 305px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list_four .top .topimg {
    /* width: 249px;
          height: 160px; */
    width: 250px;
    height: 250px;
    display: block;
}

.list_four .top .topimg img {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
}

.list_four .span {
    font-size: 18px;
    color: #434343;
    margin-top: 20px;
    text-align: center;
    padding: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.list_four .details {
    width: 108px;
    height: 40px;
    margin: auto;
    border: 1px solid #D0D0D0;
    text-align: center;
    font-size: 14px;
    color: #454545;
    line-height: 40px;
    cursor: pointer;
    background-color: #f7f7f7;
    margin-top: 15px;
}

/* .list:hover {
      box-shadow: 0px 0px 12px 4px #bfbfbf;
  } */
.list_four:hover .details {
    background-color: #2d64c8;
    border: 1px solid #2d64c8;
    color: #fff;
}

.list_four:hover .top .topimg img {
    transform: scale(1.1);
}

/* 相关案例和相关方案 */
.anlist {
    width: 447px;
    height: 460px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #f5f5f6;
    margin-bottom: 25px;
    margin-left: 29px;
    position: relative;
}

.anlist .fangan {
    position: absolute;
    width: 91px;
    height: 33px;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 33px;
    text-align: center;
    background-color: #2D64C8;
}

.anlist:nth-child(3n+1) {
    margin-left: 0;
}

.anlist .topimg {
    width: 447px;
    height: 280px;
    display: block;
    overflow: hidden;
}

.anlist .topimg img {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
}

.antext {
    width: 395px;
    margin: auto;
}

.anlist .antext .span {
    font-size: 18px;
    color: #333333;
    margin-top: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.anlist .antext .xin {
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    height: 40px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    /*要显示的行数*/
    -webkit-box-orient: vertical;
    margin-top: 15px;
}

.details {
    width: 108px;
    height: 40px;
    border: 1px solid #D0D0D0;
    text-align: center;
    font-size: 14px;
    color: #454545;
    line-height: 40px;
    cursor: pointer;
    background-color: #f7f7f7;
    margin-top: 15px;
}

/* .list:hover {
      box-shadow: 0px 0px 12px 4px #bfbfbf;
  } */
.anlist:hover .details {
    background-color: #2d64c8;
    border: 1px solid #2d64c8;
    color: #fff;
}

.anlist:hover .topimg img {
    transform: scale(1.1);
}
</style>
