<template>
  <div class="footer" v-if="fotlist!=''">
    <div class="fottom main">
      <div class="fot">
        <div class="s_left">
          <div class="fots" v-for="(item,index) in fotlist" :key="index">
            <div class="sle_top" @click="topath(item.modul_id,item.id,index)">{{item.name}}</div>
            <div class="sle_bot">
              <div class="sle_botlist"  v-for="(itemMsg,indexMsg) in item.son" :key="indexMsg" @click="topath(itemMsg.modul_id,itemMsg.id,index)"> {{itemMsg.name}}</div>
            </div>
          </div>
        </div>
        <div class="dibiao">
          <div class="ditext">{{xinxilist.company_name}} &nbsp;ICP证:{{xinxilist.web_beian}}</div>
          <div class="ditext">公司地址：{{xinxilist.company_address}}</div>
          <div style="width: 100%;height:1px;"></div>
          <div class="ditext">公司电话：{{xinxilist.company_phone}}</div>
          <div class="ditext">
            <img class="beian" src="../../assets/img/beian.png" alt="">
            {{xinxilist.web_copyright}} </div>
        </div>
      </div>
      <div class="erwima" v-if="xinxilist.weixin_path">
        <div class="erwi">
          <img :src="Baseurl+xinxilist.weixin_path" alt="">
        </div>
        <p>
          关注我们<br>了解更多资讯
        </p>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fotlist:[],//导航列表
      xinxilist:[],//底部信息
    };
  },
  inject: ["reload"],
  created() {
    setTimeout(()=>{
      this.getinfo()
    },1000)
  },
  methods: {
    //获取底部信息
    getinfo(){
      var that = this;
      that.$axios
      .get(`${this.Baseurl}/public_footer`) 
      .then(res => {
        console.log(res)
        that.fotlist = res.data.data.Columns
        that.xinxilist = res.data.data.web_base
      }).catch(err => console.log(err));
    },
    topath(mode,id,index){
      // console.log(mode)
      // console.log(id)
      // console.log(index)
      // console.log(index)
      // return false;
      if(index==0&&mode=="Gramlist"){
        this.$router.push({name:"Contact",query:{
          id:id,
          module_id:mode
        }})
      }else{
        this.$router.push({name:mode,query:{
          id:id,
          module_id:mode
        }})
      }
      // if(index==this.fotlist.length-1){
      //   this.$router.push({name:'Contact',query:{
      //   id:id,
      //   module_id:mode
      //   }})
      // }else{
      //   this.$router.push({name:mode,query:{
      //     id:id,
      //     module_id:mode
      //   }})
      // }
      localStorage.setItem("navIndex",index)
      this.reload()
    },
  },
};
</script>
<style scoped>
.footer{
  width: 100%;
  background-color: #5b5b5b;
  padding-top: 35px;
  padding-bottom: 25px;
}
.fottom{
  padding-left: 25px;
  box-sizing: border-box;
  display: flex;
}
/* .fottom .fot {
  display: flex;
} */
.erwima{
  width: 122px;
  margin-left: auto;
  margin-right: 40px;
  margin-top: 15px;
}
.erwima p {
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.erwima .erwi{
  width: 122px;
  height: 122px;
}
.erwi img{
  width: 100%;
  height: 100%;
}
.fottom .fot .s_left{
  display: flex;
}
.fottom .s_left .fots {
  margin-right: 40px;
}
.fots .sle_top {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 27px;
  cursor: pointer;
}
.sle_bot{
  margin-top: 10px;
}
.sle_bot .sle_botlist{
  font-size: 12px;
  line-height: 24px;
  color: #c4c4c4;
  cursor: pointer;
}
.sle_bot .sle_botlist:hover{
  color: #fff;
}
.dibiao{
  /* width: 800px; */
  font-size: 12px;
  color: #FFFFFF;
  line-height: 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.beian{
  width: 12px;
  height: 14px;
}
.dibiao .ditext{
  margin-right: 10px;
}
</style>