<template>
    <div class="container">
      <!-- 头部 -->
      <Header />
  
      <div class="news">
        <div class="top_img">
            <img :src="Baseurl+list.banner_image" alt="">
        </div>
        <div class="public_title">
            <div class="pumain">
                <div class="pubbiao">{{list.name}}</div>
                <div class="pubright">
                    <img class="dian" src="../../assets/img/dian.png" alt="">
                    <span class="risp" v-if="list.father">{{list.father[0].name}}</span>
                    <img class="rispi" v-if="list.father" src="../../assets/img/rispi.png" alt="">
                    <span class="risp">{{list.name}}</span>
                </div>
            </div>
        </div>
        <div class="newscont main">
            <div class="video">
                <video :src="Baseurl+olists.image" controls poster="https://www.huizhongtech.net/home_res_path/img/jieshao.jpg"></video>
            </div>
            <div class="text" v-html="olists.description"></div>
        </div>
      </div>
      
      <!-- 底部 -->
      <Footer/>
    </div>
</template>

<script>
import Header from "../owned/header.vue"
import Footer from "../owned/footer.vue"

export default {
components: {
    Header,
    Footer
},
data() {
    return {
        id:"",//二级模块ID
        module_id:'',//类型
        list:[],
        olists:[]
    };
},

created() {
    localStorage.setItem("navIndex",1)
    this.id = this.$route.query.id 
    this.module_id = this.$route.query.module_id
    this.getinfo()
},
methods: {
    getinfo(){
        //https://www.huizhongtech.net/search_module?id=310&module_id=3&page=1&keyword=SCL-61H
        var that = this;
        that.$axios
        .get(`${this.Baseurl}/search_module?id=${that.id}&module_id=${that.module_id}`) 
        .then(res => {
            console.log(res)
            that.list = res.data.data
            that.olists =res.data.data.news[0]
        }).catch(err => console.log(err));
    },
},
mounted() {
    
},

};
</script>
<style scoped>
.newscont{
    padding-top: 20px;
    padding-bottom: 60px;
}
.video{
    width: 100%;
}
.video video{
    width: 100%;
}

.text{
    font-size: 18px;
    color: #343333;
    line-height: 2;
    margin-top: 20px;
}
.text img{
    max-width: 100% !important;
    display: block;
    margin: 10px auto;
}
/* .text /deep/ p,.text /deep/ p{

} */


</style>
