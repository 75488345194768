<template>
  <div class="header" id="header">
    <div class="hearone">
      <div class="tops main">
        <div class="topsleft">
          <div class="logo" @click="toindex">
            <!-- <img src="../../assets/img/logo.png" alt=""> -->
            <img :src="Baseurl+logolist.web_logo_path" alt="">
            <!-- wap_logo_path -->
          </div>
          <div class="logotex">
            <p class="logotex1">{{logolist.company_name}}</p>
            <!-- <p class="logotex2">Huizhong Technology Tangshan Co., Ltd</p> -->
          </div>
        </div>
        <div class="topsright">
          <div class="yibiao" @click="toguan">
            汇中仪表官网
            <!-- <img src="../../assets/img/yibiao.png" alt=""> -->
          </div>
          <!-- <div class="guanw" @click="toguan">
            <p>汇中仪表官网</p>
            <img src="../../assets/img/topyou.png" alt="">
          </div> -->
          <div class="search">
            <input type="text" placeholder="输入关键词" v-model="searchtext">
            <div class="sousuo" @click="toSearch">
              <img src="../../assets/img/fabai.png" alt="">
            </div>
          </div>
          <div class="yuyan" >
            <div class="yuyancont" @click.stop="yuyanset">
              <p>语言选择</p>
              <img class="setxia" src="../../assets/img/setxia.png" alt="" 
              :style="yuyanshow?'transform: rotate(180deg);':''">
            </div>
            <div class="setle" :style="yuyanshow?'transform: scale(1, 1); opacity: 1;':''">
              <div class="setlelist active"  @click="yuyanchange('EN')">English</div>
              <div class="setlelist" @click="yuyanchange('CN')">简体中文</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="heartwo">
      <div class="nav main">
        <div class="navlist" :class="navIndex==index?'active':''" v-for="(item,index) in navlist" :key="index">
          <div class="navspan" @click="topath(item.modul_id,item.id,index,item)">
            <p>{{item.name}}</p>
            <img v-if="item.son.length>0" src="../../assets/img/setxia.png" alt="">
          </div>
          <div class="listul" v-if="item.son.length>0">
            <div class="listli" v-for="(itemMsg,indexMsg) in item.son" :key="indexMsg" @click.stop="topath(itemMsg.modul_id,itemMsg.id,index)">
              {{itemMsg.name}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fudong">
      <div class="fudlist">
        <img class="img1" src="../../assets/img/fd1.png" alt="">
        <img class="img2" src="../../assets/img/fdh1.png" alt="">
        <p>客服</p>
      </div>
      <div class="fudlist" @click="toTop">
        <img class="img1" src="../../assets/img/fd2.png" alt="">
        <img class="img2" src="../../assets/img/fdh2.png" alt="">
        <p>顶部</p>
      </div>
    </div>
  </div>
</template>

<script>
import { set } from "vue";
// import Swiper from "swiper";
export default {
  components: {
  },
  data() {
    return {
      yuyanshow:false,
      navlist:[],//导航列表
      navIndex:0,//导航当前位置
      searchtext:'',//搜索的关键词
      logolist:[]
    };
  },

  created() {
    this.navIndex = localStorage.getItem("navIndex")
    this.getinfo()
  },
 
  inject: ["reload"],
  methods: {
    //切换语言
    yuyanchange(e) {
      var yuyan = e;
      var nowhref = window.location.href;
      if (yuyan == "EN") {
        var newhref =''
        if(nowhref.indexOf("www")!='-1'){
          newhref = nowhref.replace("https://www.", "https://en.");
        }else{
          newhref = nowhref.replace("https://", "https://en.");
        }
        
        console.log(newhref);
        // window.location.href = newhref;
        window.open(newhref)
      }else{
        this.reload()
      }
    },
    //获取头部信息
    getinfo(){
      var that = this;
      that.$axios
      .get(`${this.Baseurl}/public_header`) 
      .then(res => {
        console.log(res)
        that.navlist = res.data.data.Columns
        that.logolist =res.data.data.web_base
      }).catch(err => console.log(err));
    },
    //回到顶部
    toTop() {
      document.querySelector("#header").scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    },
    //去首页
    toindex(){
      this.$router.push({name:'Index'})
    },
    //去汇中仪表官网
    toguan(){
      window.open("https://www.huizhong.co/home")
    },
    //选择语言弹窗的展示与否
    yuyanset(){
      this.yuyanshow =!this.yuyanshow
    },
    topath(mode,id,index,item){
      // if(index==this.navlist.length-1){
      //   this.$router.push({name:'Contact',query:{
      //   id:id,
      //   module_id:mode
      //   }})
      // }else if(index==0){
      //   this.$router.push({name:'Index'})
      // }else{
      //   //console.log(mode,id)
      //   this.$router.push({name:mode,query:{
      //     id:id,
      //     module_id:mode
      //   }})
      //   // if(index&&item.son.length>0){
      //   //   this.$router.push({name:mode,query:{
      //   //     id:item.son[0].id,
      //   //     module_id:item.son[0].mode
      //   //   }})
      //   // }else{
      //   //   this.$router.push({name:mode,query:{
      //   //     id:id,
      //   //     module_id:mode
      //   //   }})
      //   // }
      // }
      // console.log(mode)
      // console.log(id)
      // console.log(index)
      // console.log(item)
      console.log(index)
      if(index==0){
        this.$router.push({name:'Index'})
      }
      else if(index==1&&mode=="Gramlist"){
        this.$router.push({name:"Contact",query:{
          id:id,
          module_id:mode
        }})
      }else{
        this.$router.push({name:mode,query:{
          id:id,
          module_id:mode
        }})
      }
      // return false
      localStorage.setItem("navIndex",index)
      this.reload()
    },
    //去搜索页
    toSearch(){
      console.log(this.searchtext)
      //keyword=去
      this.$router.push({name:'Search',query:{
        keyword:this.searchtext
      }})
      this.reload()
    },
  },
  mounted() {
    var that =this
    document.querySelector("#app").onclick=function(){
      that.yuyanshow = false
    }
  },
};
</script>
<style scoped>
  
  .fudong{
    position: fixed;
    right: 40px;
    bottom: 240px;
    font-size: 30px;
    z-index: 9999;
    width: 73px;
    height: 212px;
    background: #FFFFFF;
    border-radius: 37px;
    /* box-shadow: 0px 0px 10px 1px #dcdcdc;  */
    box-shadow: 0px 0px 5px 1px #d1cccc;
    transform: scale(0.8);
  }
  .fudlist{
    /* background-color: pink; */
    cursor: pointer;
    margin-top: 30px;
  }
  .fudlist>img{
    width: 39px;
    height: 37px; 
    display: block;
    margin: auto;
  }
  .fudlist .img1{
    display: block;
  }
  .fudlist .img2{
    display: none;
  }
  .fudlist:hover p{
    color: #2D64C8;
  }
  .fudlist:hover .img1{
    display: none;
  }
  .fudlist:hover .img2{
    display: block;
  }
  .fudlist>p{
    font-size: 16px;
    color: #434343;
    line-height: 30px;
    text-align: center;
    margin: auto;
    /* font-weight: bold; */
  }
 
  .nav{
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 30px;
  }
  .navlist{
    position: relative;
    height: 100%;
    /* font-size: 20px; */
    font-size: 18px;
    color: #1E1E1E;
    margin-right: 100px;
    cursor: pointer;
  }
  .navlist.active{
    color: #EE1D23;
  }
  .navlist.active::after{
    position: absolute;
    content: "";
    bottom: 0;
    width: 36px;
    height: 3px;
    left: 50%;
    margin-left: -18px;
    background-color: #ee1d23;
    z-index: 1;
  }
  .navlist:hover{
    color: #EE1D23;
  }
  .navlist:hover::after{
    position: absolute;
    content: "";
    bottom: 0;
    width: 36px;
    height: 3px;
    left: 50%;
    margin-left: -18px;
    background-color: #ee1d23;
    z-index: 1;
  }
  .navlist .navspan{
    display: flex;
    align-items: center;
    height: 100%;
  }
  .navlist .navspan p{
    margin-right: 5px;
  }
  .navlist .navspan img{
    width: 9px;
    height: 5px;
    display: block;
    
  }
  /* .navlist:hover .listul{
    display: block;
  }
  .listul{
    position: absolute;
    background-color: rgba(243,243,243,0.85);
    padding: 20px 0;
    width: 162px;
    border-radius: 0 0 8px 8px;
    top: 100%;
    left: 50%;
    margin-left: -81px;
    z-index: 3;
    display: none;
  }
  .listul .listli{
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #211814;
    cursor: pointer;
  }
  .listul .listli:hover{
    background-color: #fff;
    color: #EE1D23;
  } */
  /* 修改头部 */
  .navlist:hover .listul{
    display: flex;
  }
  .navlist:nth-child(1) .listul{
    margin-left: -630px;
    padding-left: 630px;
  }
  .navlist:nth-child(2) .listul{
    margin-left: -761px;
    padding-left: 750px;
  }
  /* 480 */
  .navlist:nth-child(3) .listul{
    margin-left: -947px;
    padding-left: 905px;
  }
  .navlist:nth-child(4) .listul{
    margin-left: -1135px;
    padding-left: 1080px;
  }
  .navlist:nth-child(5) .listul{
    margin-left: -1301px;
    padding-left: 1255px;
  }
  .navlist:nth-child(6) .listul{
    margin-left: -1487px;
    padding-left: 1460px;
  }
  .navlist:nth-child(7) .listul{
    margin-left: -1691px;
    padding-left: 1640px;
  }
  .listul{
    position: absolute;
    /* background-color: rgba(0,0,0,0.3); */
    background-color: rgba(2, 77, 141, 0.6);
    /* padding: 10px 0; */
    width: 1920px;
    top: 100%;
    z-index: 3;
    display: none;
  }
  .listul .listli{
    margin-right: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    position: relative;
    font-weight: 100;
  }
  .listul .listli:hover{
    font-weight: normal;
    /* color: #2d8cf0; */
    /* background-color: rgb(209, 164, 164);
    color: #EE1D23; */
  }
  .listul .listli:hover::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    background-color: #fff;
    bottom: 0;
    left: 0;
  }
  /* 修改头部end */
  
  .hearone{
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
  }
  .tops{
    display: flex;
    align-items: center;
  }
  .topsleft{
    display: flex;
    align-items: center;
  }
  .topsleft .logo{
    width: 172px;
    height: 47px;
    cursor: pointer;
  }
  .topsleft .logo>img{
    width: 100%;
    height: 100%;
    display: block;
  }
  .topsleft .logotex{
    margin-left: 25px;
  }
  .topsleft .logotex .logotex1{
    font-size: 25px;
    font-weight: bold;
    color: #211814;
  }
  .topsleft .logotex .logotex2{
    font-size: 11px;
    font-weight: 800;
    color: #211814;
  }
  .topsright{
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .topsright .guanw{
    display: flex;
    align-items: center;
    width: 162px;
    height: 39px;
    background: #FFFFFF;
    border: 2px solid #D1211B;
    border-radius: 20px;
    cursor: pointer;
  }
  .topsright .guanw>p{
    font-size: 18px;
    color: #D1211B;
    margin-left: 15px;
  }
  .topsright .guanw>img{
    width: 20px;
    height: 14px;
    display: block;
    margin-left: 5px;
  }
  .yibiao{
    /* width: 171px;
    height: 39px; */
    width: 175px;
    height: 44px;
    background-image: url(../../assets/img/yibiao.png);
    background-size: 100% 100%;
    font-size: 18px;
    color: #D1211B;
    line-height: 44px;
    padding-left: 50px;
    font-weight: inherit;
    box-sizing: border-box;
    cursor: pointer;
  }
  
  /* .yibiao>img{
    width: 100%;
    height: 100%;
    display: block;
  } */
  .topsright .search{
    margin-left: 40px;
    width: 213px;
    height: 39px;
    background: #F8F8F8;
    border: 2px solid #DFDFDF;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
  }
  .topsright .search input{
    /* background-color: pink; */
    width: 130px;
    border: none;
    outline: none;
    padding: 0 20px;
    font-size: 14px;
    color: #8E8E8E;
    background: #F8F8F8;
  }
  .topsright .search input::placeholder{
    color: #C4C4C4;
  }
  .topsright .search .sousuo{
    width: 36px;
    height: 36px;
    background: #8E8E8E;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
  }
  .topsright .search .sousuo>img{
    width: 18px;
    height: 18px;
    display: block;
  }
  .yuyan{
    position: relative;
    /* display: flex;
    align-items: center; */
    margin-left: 22px;
  }
  .yuyancont{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .yuyan .yuyancont>p{
    font-size: 18px;
    color: #211814;
  }
  .yuyan .yuyancont .setxia{
    width: 9px;
    height: 5px;
    display: block;
    margin-left: 9px;
    /* transition: all 0.3s; */
  }
  .setle{
    width: auto;
    min-width: 80px;
    max-height: 200px;
    overflow: auto;
    margin: 5px 0;
    padding: 5px 0;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
    position: absolute;
    z-index: 2;
    transform-origin: center top 0px;
    transition: all 0.3s;
    will-change: top, left;
    top: 30px;
    left: 0;
    transform: scale(1, 0);
    opacity: 0;
  }
  .setlelist{
    line-height: normal;
    padding: 7px 16px;
    clear: both;
    color: #515a6e;
    font-size: 12px !important;
    white-space: nowrap;
    list-style: none;
    cursor: pointer;
    transition: background .2s ease-in-out;
    display: block;
    cursor: pointer;
  }
  .setlelist.active{
    color: #2d8cf0;
    background-color: #f3f3f3;
    display: block;
  }
  .setlelist:hover{
    color: #2d8cf0;
    background-color: #f3f3f3;
    display: block;
  }


  .heartwo{
    width: 100%;
    height: 57px;
    background-color: #f3f3f3;
  }
</style>
