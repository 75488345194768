<template>
    <div class="container">
      <!-- 头部 -->
      <Header />
  
      <div class="news">
        <div class="newscont main">
            <div class="newleft">
                <span class="title">{{list.title}}</span>
                <div class="stv">
                    <div class="time">{{list.f_time.slice(0,10)}}</div>
                    <div class="zihao" @click="jia">A+</div>
                    <div class="zihao" @click="jian">A-</div>
                </div>
                <div class="text" :style="{fontSize:fontSize+'px'}" v-html="list.content">
                </div>
            </div>
            <div class="newright">
                <div class="top">相关推荐</div>
                <div class="list" v-for="(item,index) in list.recommend" :key="index">
                    <div class="listimg">
                        <img :src="Baseurl+item.image" alt="">
                    </div>
                    <span>{{item.title}}</span>
                    <div class="time">{{item.f_time.slice(0,10)}}</div>
                </div>
            </div>
        </div>
      </div>
      
      <!-- 底部 -->
      <Footer/>
    </div>
</template>

<script>
import Header from "../owned/header.vue"
import Footer from "../owned/footer.vue"

export default {
components: {
    Header,
    Footer
},
data() {
    return {
        fontSize:16,
        id:'',
        list:[],
    };
},

created() {
    localStorage.setItem("navIndex",1)
    this.id = this.$route.query.id
    this.getinfo()
},
methods: {
    jia(){
        this.fontSize = this.fontSize+1
    },
    jian(){
        this.fontSize = this.fontSize-1
    },
    getinfo() {
        var that = this;
        // https://www.huizhongtech.net/top_search?id=44&keyword=新型阀控预付费解决方案
        that.$axios
          .get(`${this.Baseurl}/top_search?id=${that.id}`)
          .then(res => {
            console.log(res)
            that.list= res.data.data[0]
        }).catch(err => console.log(err));
    },

},
mounted() {
    
},

};
</script>
<style scoped>
.newscont{
    display: flex;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 60px;
}
.newleft{
    width: 980px;
}
.newleft .title{
    /* font-size: 36px; */
    font-size: 26px;
    color: #343434;
    line-height: 55px;
}
.stv{
    margin-top: 15px;
    display: flex;
    align-items: center;
}
.time{
font-size: 16px;
color: #757575;
margin-right: 50px;
}
.zihao{
    font-size: 16px;
    color: #757575;
    margin-right: 20px;
    cursor: pointer;
}
.text{
    color: #343333;
    line-height: 2;
    margin-top: 30px;
    
}
.text img{
    max-width: 100% !important;
    display: block;
    margin: 10px auto;
}
/* .text /deep/ p,.text /deep/ p{

} */

.newright{
    width: 290px;
}
.newright .top{
    font-size: 24px;
    color: #434343;
    line-height: 34px;
    padding-left: 20px;
    position: relative;
}
.newright .top::before{
    position: absolute;
    content: "";
    width: 4px;
    height: 26px;
    background-color: #2d64c8;
    left: 2px;
    top: 50%;
    margin-top: -13px;
}
.list{
    margin-top: 20px;
    cursor: pointer;
}
.list .listimg{
    width: 290px;
    height: 156px;
    overflow:hidden;
}
.list .listimg img{
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
}

.list span{
    font-size: 18px;
    color: #434343;
    line-height: 34px;
    margin-top: 10px;
    display: block;
}
.list .time{
    font-size: 14px;
    font-family: Arial;
    color: #434343;
    line-height: 20px;
    /* margin-top: 10px; */
}
.list:hover .listimg img{
    transform: scale(1.03);
}
.list:hover span{
    color: #2d64c8;
}
</style>
