import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

import Footer from '../views/owned/footer.vue'// 公共底部
import Header from '../views/owned/header.vue'// 公共头部
import News from '../views/news/news.vue'//新闻列表页(新闻中心)
import Newsdetails from '../views/news/newsdetails.vue'//新闻详情页
import Brief from '../views/news/brief.vue'//公司简介
import Down from '../views/news/down.vue'//文档下载
import Product from '../views/group/product.vue'//产品列表
import Productdetails from '../views/group/productdetails.vue'//产品详情
import Anlilist from '../views/group/anlilist.vue'// 案例列表
import Anlidetails from '../views/group/anlidetails.vue'// 案例详情
import Gramlist from '../views/group/gramlist.vue'// 解决方案列表
import Gramdetails from '../views/group/gramdetails.vue'// 解决方案详情
import Search from '../views/search/search.vue'// 搜索结果页
import Contact from '../views/contact/contact.vue'//联系我们


Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Index',//首页
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/footer',
    name:'Footer',//pc端公共底部
    component: () => import('../views/owned/footer.vue')
  },
  {
    path: '/header',
    name:'Header', //pc端公共头部
    component: () => import('../views/owned/header.vue')
  },
  {
    path: '/news',
    name:'News',//pc端新闻列表页(新闻中心)
    component: () => import('../views/news/news.vue')
  },
  {
    path: '/newsdetails',
    name:'Newsdetails',//pc端新闻详情页
    component: () => import('../views/news/newsdetails.vue')
  },
  {
    path: '/brief',
    name:'Brief',//pc端公司简介
    component: () => import('../views/news/brief.vue')
  },
  {
    path: '/down',
    name:'Down',//pc端文档下载
    component: () => import('../views/news/down.vue')
  },
  {
    path: '/product',
    name:'Product',//pc端产品列表
    component: () => import('../views/group/product.vue')
  },
  {
    path: '/productdetails',
    name:'Productdetails',//pc端产品详情
    component: () => import('../views/group/productdetails.vue')
  },
  {
    path: '/contact',
    name:'Contact',//pc端联系我们
    component: () => import('../views/contact/contact.vue')
  },
  {
    path:'/anlilist',
    name:'Anlilist',//pc端案例列表
    component: () => import('../views/group/anlilist.vue')
  },
  {
    path:'/anlidetails',
    name:'Anlidetails',//pc端案例详情页
    component: () => import('../views/group/anlidetails.vue')
  },
  {
    path:'/gramlist',
    name:'Gramlist',//pc端解决方案列表
    component: () => import('../views/group/gramlist.vue')
  },
  {
    path:'/gramdetails',
    name:'Gramdetails',//pc端解决方案详情
    component: () => import('../views/group/gramdetails.vue')
  },
  {
    path: '/search',
    name:'Search', //pc端搜索结果页
    component: () => import('../views/search/search.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
