import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//vue 路由切换，页面定位到顶部
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
});
import configURL from '../public/config.json'
import axios from 'axios'
Vue.prototype.$axios = axios

// console.log(configURL)
Vue.prototype.Baseurl = configURL.Baseurl
Vue.prototype.Baseurlzs = configURL.Baseurlzs


import gettoken from './assets/gettoken';
Vue.prototype.$gettoken = gettoken;
//Baseurlzs
// console.log(Vue.prototype.Baseurl)

// axios.get("/ai_gate/config.json").then((res)=>{
// 	console.log(res)
// 	Vue.prototype.Baseurl = res.data.Baseurl
// 	new Vue({
// 		render: h => h(App),
// 		router,
// 	}).$mount('#app')
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
