<template>
    <div class="container">
      <!-- 头部 -->
      <Header />
  
      <div class="news">
        <div class="top_img">
            <img :src="Baseurl+list.father[0].banner_image" alt="">
        </div>
        <!-- 323 303 -->
        <!-- 300 300 -->
        <div class="public_title">
            <div class="pumain">
                <!-- <div class="pubbiao">智能超声测流模组</div>
                <div class="pubright">
                    <img class="dian" src="../../assets/img/dian.png" alt="">
                    <span class="risp">智能超声测流模组</span>
                    <img class="rispi" src="../../assets/img/rispi.png" alt="">
                    <span class="risp">产品介绍</span>
                </div> -->
                <div class="pubbiao">{{list.name}}</div>
                <div class="pubright">
                    <img class="dian" src="../../assets/img/dian.png" alt="">
                    <span class="risp" v-if="list.father">{{list.father[0].name}}</span>
                    <img class="rispi" v-if="list.father" src="../../assets/img/rispi.png" alt="">
                    <span class="risp">{{list.name}}</span>
                </div>
            </div>
        </div>
        <div class="newscont_bg main">
            <!-- <div class="tyoeFour" v-for="(item,index) in list" :key="index">
                <div class="an_top">{{item}}</div>
                <div class="newscont ">
                    <div class="anlist"  v-for="(item,index) in 3" :key="index">
                        <div class="topimg">
                            <img src="../../assets/img/index1.jpg" alt="">
                        </div>
                        <div class="antext">
                            <span class="span">末端管控一体化方案</span>
                            <div class="xin">末端供热计量管控一体化解决方案是集热计量收费温控一体化、户端平衡调节户端平衡调节户端平衡调节</div>
                            <div class="details" @click="todetails">查看详情</div>
                        </div>
                    </div> 
                </div>
            </div> -->
            <div class="newscont">
                <div class="anlist" v-for="(item,index) in olists" :key="index"  @click="todetails(item.id)">
                    <div class="topimg">
                        <img :src="Baseurl+item.image" alt="">
                    </div>
                    <div class="antext">
                        <span class="span">{{item.title}}</span>
                        <div class="xin">{{item.en_title}}</div>
                        <div class="details">查看详情</div>
                    </div>
                </div>
            </div>
            
        </div>
        
      </div>
      
      <!-- 底部 -->
      <Footer/>
    </div>
</template>

<script>
import Header from "../owned/header.vue"
import Footer from "../owned/footer.vue"

export default {
components: {
    Header,
    Footer
},
data() {
    return {
        list:["智能超声测流模组","高精度换能器","水表流量检定装置","超声水表生产线"],
        id: "",//二级模块ID
        module_id: '',//类型
        list: [],//页面内容
        olists:[],
    };
},

created() {
    this.id = this.$route.query.id
    this.module_id = this.$route.query.module_id
    this.getinfo()
},
inject: ["reload"],
methods: {
    getinfo() {
        var that = this;
        that.$axios
            .get(`${this.Baseurl}/search_module?id=${that.id}&module_id=${that.module_id}`)
            .then(res => {
                // console.log(res)
                that.list = res.data.data
                console.log(that.list)
                that.olists=res.data.data.news
            }).catch(err => console.log(err));
    },
    todetails(id){
        console.log(id)
                console.log(this.module_id)
                // return false;
        // this.$router.push({name:'Anlidetails'})
        this.$router.push({ name: 'Anlidetails',query:{
            id:id,
            module_id:this.module_id,
        }})
        this.reload()
    },
    //去案例详情页
    toPlan(id) {
                this.$router.push({ name: 'Anlidetails',query:{
                    id:id,
                    module_id:this.module_id,
                }})
                this.reload()
            },

},
mounted() {
    
},

};
</script>
<style scoped>
/* .newscont_bg{
    background-color: #f5f5f5; 
    padding-bottom: 44px;
} */
.tyoeFour{
    border-top: 2px solid #eeeeee;
    padding-top: 30px;
}
.tyoeFour .an_top{
    font-size: 30px;
    color: #1E1E1E;
    position: relative;
    padding-left: 27px;
}
.tyoeFour .an_top::before{
    position: absolute;
    content: "";
    width: 4px;
    height: 29px;
    left: 0;
    background-color: #2d64c8;
    top: 50%;
    margin-top: -14px;
}
.newscont{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}
.anlist{
    width: 447px;
    height: 500px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #f5f5f6;
    margin-bottom: 25px;
    margin-left: 29px;
    position: relative;
}
.anlist:nth-child(3n+1){
    margin-left: 0;
}

.anlist .topimg{
    width: 447px;
    height: 280px;
    display: block;
    overflow: hidden;
}
.anlist .topimg img{
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
}
.antext{
    width: 395px;
    margin: auto;
}
.anlist .antext .span{
    font-size: 24px;
    color: #333333;
    margin-top: 25px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}
.anlist .antext .xin{
    font-size: 18px;
    color: #333333;
    line-height: 27px;
    height: 54px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;/*要显示的行数*/
    -webkit-box-orient: vertical;
    margin-top: 20px;
}
.details{
    width: 108px;
    height: 40px;
    border: 1px solid #D0D0D0;
    text-align: center;
    font-size: 16px;
    color: #454545;
    line-height: 40px;
    cursor: pointer;
    background-color: #f7f7f7;
    margin-top: 15px;
}
/* .list:hover {
    box-shadow: 0px 0px 12px 4px #bfbfbf;
} */
.anlist:hover .details{
    background-color: #2d64c8;
    border: 1px solid #2d64c8;
    color: #fff;
}
.anlist:hover .topimg img{
    transform: scale(1.1);
}
</style>
