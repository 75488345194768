<template>
    <div class="container">
        <!-- 头部 -->
        <Header />
        <div class="news">
            <div class="public_title">
                <div class="pumain">
                    <!-- <div class="pubbiao">智能超声测流模组</div> -->
                    <div class="pubright">
                        <img class="dian" src="../../assets/img/dian.png" alt="">
                        <span class="risp" v-if="list.parents">{{list.parents[0].name}}</span>
                        <img class="rispi" v-if="list.parents" src="../../assets/img/rispi.png" alt="">
                        <span class="risp" v-if="list.father">{{list.father[0].name}}</span>
                    </div>
                </div>
            </div>
            <div class="newscont_bg main">
                <div class="pro_one">
                    <div class="proone_left">
                        <div class="left_tit">{{list.title}}</div>
                        <div class="neirong" v-html="list.description"></div>
                        <div class="xinxi">
                            <img src="../../assets/img/xin1.png" alt="">
                            <p>咨询热线：{{list.tel}}</p>
                        </div>
                        <div class="xinxi">
                            <img src="../../assets/img/xin2.png" alt="">
                            <p>邮箱地址：{{list.email}}</p>
                        </div>
                    </div>
                    <div class="proone_right">
                        <div class="lunbotu">
                            <div class="swiper-container-wrapper">
                                <div class="one_left">
                                    <div class="swiper-container gallery-top">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide" v-for="(item,index) in list.images" :key="index">
                                                <div class="deta_leftimg">
                                                    <img :src="Baseurl+item.image" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="one_right">
                                    <div class="detailone">
                                        <!-- Add Arrows -->
                                        <div class="swiper-button-prev"></div>
                                            <div class="swiper-button-next"></div>
                                        <div class="swiper-container gallery-thumbs">
                                            <div class="swiper-wrapper">
                                                <div class="swiper-slide" v-for="(item,index) in list.images" :key="index">
                                                    <div class="deta_rightimg">
                                                        <img :src="Baseurl+item.image" alt="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pro_two" v-if="list.content">
                    <div class="left_tit">技术参数</div>
                    <div class="skill" v-html="list.content"></div>
                </div>
                <div class="pro_there" v-if="list.related_project_list&&list.related_project_list.length>0">
                    <div class="proth_top">
                        <div class="left_tit">相关产品</div>
                        <div class="more" @click="Project_more">查看更多</div>
                    </div>
                    <div class="newscont">
                        <div class="list_one" v-if="list.related_project_list.length==1" v-for="(item,index) in list.related_project_list" :key="index" @click="toProject(item.id)">
                            <div class="top">
                                <div class="topimg">
                                    <img :src="Baseurl+item.image" alt="">
                                </div>
                            </div>
                            <div class="right">
                                <div class="span">{{item.title}}</div>
                                <div class="text">{{item.en_title}}</div>
                                <div class="details">查看详情</div>
                            </div>
                        </div>
                        <div class="list_two" v-if="list.related_project_list.length==2" v-for="(item,index) in list.related_project_list" :key="index" @click="toProject(item.id)">
                            <div class="top">
                                <div class="topimg">
                                    <img :src="Baseurl+item.image" alt="">
                                </div>
                            </div>
                            <div class="right">
                                <div class="span">{{item.title}}</div>
                                <div class="text">{{item.en_title}}</div>
                                <div class="details">查看详情</div>
                            </div>
                        </div>
                        <div class="list_there" v-if="list.related_project_list.length==3" v-for="(item,index) in list.related_project_list" :key="index" @click="toProject(item.id)">
                            <div class="top">
                                <div class="topimg">
                                    <img :src="Baseurl+item.image" alt="">
                                </div>
                            </div>
                            <div class="span">{{item.title}}</div>
                            <div class="details">查看详情</div>
                        </div>
                        <div class="list_four" v-if="list.related_project_list.length==4" v-for="(item,index) in list.related_project_list" :key="index" @click="toProject(item.id)">
                            <div class="top">
                                <div class="topimg">
                                    <img :src="Baseurl+item.image" alt="">
                                </div>
                            </div>
                            <div class="span">{{item.title}}</div>
                            <div class="details">查看详情</div>
                        </div>
                    </div>
                </div>
                <div class="pro_there main" v-if="list.related_plan_list&&list.related_plan_list.length>0">
                    <div class="proth_top">
                        <div class="left_tit">相关案例</div>
                        <div class="more" @click="Plan_more">查看更多</div>
                    </div>
                    <div class="newscont">
                        <div class="anlist" v-for="(item,index) in list.related_plan_list" :key="index" @click="toPlan(item.id)">
                            <div class="topimg">
                                <img :src="Baseurl+item.image" alt="">
                            </div>
                            <div class="antext">
                                <span class="span">{{item.title}}</span>
                                <div class="xin">{{item.en_title}}</div>
                                <div class="details">查看详情</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pro_there main" v-if="list.related_case_list&&list.related_case_list.length>0">
                    <div class="proth_top">
                        <div class="left_tit">相关方案</div>
                        <div class="more" @click="Case_more">查看更多</div>
                    </div>
                    <div class="newscont">
                        <div class="anlist" v-for="(item,index) in list.related_case_list" :key="index" @click="toCase(item.id)">
                            <div class="fangan">解决方案</div>
                            <div class="topimg">
                                <img :src="Baseurl+item.image" alt="">
                            </div>
                            <div class="antext">
                                <span class="span">{{item.title}}</span>
                                <div class="xin">{{item.en_title}}</div>
                                <div class="details">查看详情</div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        <!-- 底部 -->
        <Footer />
    </div>
</template>

<script>
    import Header from "../owned/header.vue"
    import Footer from "../owned/footer.vue"
    import Swiper from "swiper";

    export default {
        components: {
            Header,
            Footer
        },
        data() {
            return {
                id: "",//二级模块ID
                module_id: '',//类型
                list:[],
            };
        },

        created() {
            this.id = this.$route.query.id
            this.getinfo()
            
        },
        inject: ["reload"],
        methods: {
            getinfo() {
                var that = this;
                that.$axios
                .get(`${this.Baseurl}/top_search?id=${that.id}`)
                .then(res => {
                    console.log(res)
                    that.list= res.data.data[0]
                    that.$nextTick(() => {
                        that.lunbo()
                    })
                }).catch(err => console.log(err));
            },
            //去产品详情页
            toProject(id) {
                //related_project
                this.$router.push({ name: 'Productdetails',query:{
                    id:id,
                    module_id:this.module_id,
                }})
                this.reload()
            },
            Project_more(){
                console.log(this.list.related_project_list)
                var nli = this.list.related_project_list[0].father[0]
                this.$router.push({name:nli.modul_id,query:{
                    id:nli.id,
                    // id:this.list.father[0].id,
                    module_id:nli.modul_id,
                }})
            },
            //去案例详情页
            toPlan(id) {
                this.$router.push({ name: 'Anlidetails',query:{
                    id:id,
                    module_id:this.module_id,
                }})
                this.reload()
            },
            //去案例更多
            Plan_more(){
                console.log(this.list.related_plan_list)
                var nli = this.list.related_plan_list[0].father[0]
                this.$router.push({name:"Anlilist",query:{
                    id:nli.id,
                    module_id:nli.modul_id,
                }})
            },

            //去方案详情页
            toCase(id) {
                this.$router.push({ name: 'Gramdetails',query:{
                    id:id,
                    module_id:this.module_id,
                }})
                this.reload()
            },
            //去方案更多
            Case_more(){
                console.log(this.list.related_case_list)
                var nli = this.list.related_case_list[0].father[0]
                this.$router.push({name:nli.modul_id,query:{
                    id:nli.id,
                    module_id:nli.modul_id,
                }})
            },
            lunbo() {
                var galleryThumbs = new Swiper(".gallery-thumbs", {
                    // direction: "horizontal",
                    spaceBetween: 50,
                    slidesPerView: 4,
                    freeMode: false,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    // breakpoints: {
                    //     480: {
                    //         direction: "vertical",
                    //         slidesPerView: 4,
                    //     }
                    // }
                });
                var galleryTop = new Swiper(".gallery-top", {
                    // direction: "horizontal",
                    // spaceBetween: 50,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    },
                    thumbs: {
                        swiper: galleryThumbs
                    }
                });
            },

        },
        mounted() {

        },

    };
</script>
<style scoped>
    
    .newscont_bg {
        /* padding-top: 56px; */
        padding-bottom: 44px;
    }

    .newscont {
        display: flex;
        flex-wrap: wrap;
    }

    .pro_one {
        display: flex;
        padding-bottom: 40px;
    }

    .proone_left {
        width: 680px;
    }

    .left_tit {
        font-size: 28px;
        color: #434343;
        margin-bottom: 30px;
    }

    .neirong {
        margin-bottom: 30px;
        font-size: 16px !important;
        color: #1e1e1e !important;
        /* font-weight: bold; */
        line-height: 30px;
    }
    .neirong /deep/ span,.neirong /deep/ p{
        font-size: 20px !important;
        color: #1e1e1e !important;
        line-height: 35px;
    }
    .neirong /deep/ span{
        font-weight: bold; 
    }

    .text>span {
        font-size: 20px;
        color: #1e1e1e;
        font-weight: bold;
        line-height: 35px;
    }

    .text>p {
        font-size: 20px;
        color: #1e1e1e;
    }

    .xinxi {
        width: 320px;
        height: 45px;
        background: #F5F5F5;
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .xinxi>img {
        width: 25px;
        height: 19px;
        margin-left: 15px;
        margin-right: 12px;
        display: block;
    }

    .xinxi>p {
        font-size: 16px;
        color: #1E1E1E;
    }

    .proone_right {
        width: 700px;
        margin-top: 50px;
    }



    .detailone {
        width: 500px;
        margin: auto;
        position: relative;
    }

    /* 联动轮播图 */
    /* .lunbotu {
        padding-bottom: 37px;
    } */

    .swiper-container-wrapper {
        width: 100%;
    }


    /* 上边的大图 */
    .gallery-top {
       width: 400px;
       height: 400px;
    }

    .deta_leftimg {
        width: 100%;
        height: 100%;
    }

    .deta_leftimg img {
        width: 100%;
        height: 100%;
        display: block;
    }

    /* 下边的缩略图 */
    .gallery-thumbs {
        width: 500px;
        display: flex;
        position: relative;
    }

    .gallery-thumbs .swiper-wrapper {
        flex-direction: row;
        height: 85px;
    }

    .gallery-thumbs .swiper-slide {
        width: 85px;
        height: 85px !important;
        cursor: pointer;
    }
   
    .deta_rightimg {
        width: 100%;
        height: 100%;
        background-color: #f7f7f7;
        box-sizing: border-box;
        border: 6px solid #f7f7f7;
    }

    .deta_rightimg img {
        width: 100%;
        height: 100%;
        display: block;
    }
    .gallery-thumbs .swiper-slide-thumb-active .deta_rightimg{
        border: 6px solid #2d64c8;
    }
    /* 右边箭头 */
    .one_right {
        position: relative;
        display: flex;
        align-items: center;
    }

    .swiper-button-next,
    .swiper-button-prev {
        color: #cfcfcf;
        width: 16px;
        height: 29px;
        /* opacity: 1; */
    }
    .swiper-button-next{
        right: -65px;
    }
    .swiper-button-prev{
        left: -65px;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        font-size: 26px;
    }
    /* 技术参数 */
    .pro_two{
        border-top: 2px solid #eeeeee;
        padding-top: 40px;
    }
    .skill{
        width: 100%;
        margin: auto;
        margin-top: 40px;
        padding-bottom: 40px;
    }
    .skill /deep/ table {
        width: 100%;
    }
    .skill /deep/  .ue-table-interlace-color-double {
        background-color: #f7faff;
    }
    .skill /deep/  table td {
        word-break: break-all !important;
        border-color: rgb(221, 221, 221) !important;
        border-width: 1px !important;
        border-style: solid !important;
        padding: 0 10px;
    }
    /* .skill table td:nth-child(1){
        width: 100px;
    } */
    .skill /deep/  table span {
        line-height: 40px;
    }



    .pro_there{
        padding-top: 40px;
        border-top: 2px solid #eeeeee;
    }
    .proth_top{
        display: flex;
        align-items: center;
    }
    .more{
        margin-left: auto;
        font-size: 16px;
        text-decoration: underline;
        color: #434343;
        cursor: pointer;
        margin-bottom: 30px;
    }
    .more:hover{
        color: #2d64c8;
    }
    
</style>