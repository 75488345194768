<template>
    <div class="container">
        <!-- 头部 -->
        <Header />
        <div class="news">
            <div class="top_img" v-if="list.father">
                <img :src="Baseurl+list.father[0].banner_image" alt="">
            </div>
            <div class="public_title">
                <div class="pumain">
                    <div class="pubbiao">{{list.name}}</div>
                    <div class="pubright">
                        <img class="dian" src="../../assets/img/dian.png" alt="">
                        <span class="risp" v-if="list.parents">{{list.parents[0].name}}</span>
                        <img class="rispi" v-if="list.parents" src="../../assets/img/rispi.png" alt="">
                        <span class="risp" v-if="list.father">{{list.father[0].name}}</span>
                    </div>
                </div>
            </div>
            <div class="newscont_bg">
                <div class="pro_one">
                    <div class="title">
                        <p>{{list.title}}</p>
                    </div>
                    <div class="enter_nav">
                        <div class="enter_tit"  :class="hoverIndex==index?'active':''" 
                        v-for="(item,index) in list.content" :key="index" @mouseover="hover(index)">{{item.title}}</div>
                    </div>
                    <div class="enter main">
                        <div class="enter_box" v-if="hoverIndex==index" v-for="(item,index) in list.content" :key="index">
                            <div class="enter_text" v-html="item.content"></div>
                        </div>
                    </div>
                </div>
                <div class="pro_there main" v-if="list.related_project_list&&list.related_project_list.length>0">
                    <div class="proth_top">
                        <div class="left_tit">相关产品</div>
                        <div class="more" @click="Project_more">查看更多</div>
                    </div>
                    <div class="newscont">
                        <div class="list_one" v-if="list.related_project_list.length==1" v-for="(item,index) in list.related_project_list" :key="index" @click="toProject(item.id)">
                            <div class="top">
                                <div class="topimg">
                                    <img :src="Baseurl+item.image" alt="">
                                </div>
                            </div>
                            <div class="right">
                                <div class="span">{{item.title}}</div>
                                <div class="text">{{item.en_title}}</div>
                                <div class="details">查看详情</div>
                            </div>
                        </div>
                        <div class="list_two" v-if="list.related_project_list.length==2" v-for="(item,index) in list.related_project_list" :key="index" @click="toProject(item.id)">
                            <div class="top">
                                <div class="topimg">
                                    <img :src="Baseurl+item.image" alt="">
                                </div>
                            </div>
                            <div class="right">
                                <div class="span">{{item.title}}</div>
                                <div class="text">{{item.en_title}}</div>
                                <div class="details">查看详情</div>
                            </div>
                        </div>
                        <div class="list_there" v-if="list.related_project_list.length==3" v-for="(item,index) in list.related_project_list" :key="index" @click="toProject(item.id)">
                            <div class="top">
                                <div class="topimg">
                                    <img :src="Baseurl+item.image" alt="">
                                </div>
                            </div>
                            <div class="span">{{item.title}}</div>
                            <div class="details">查看详情</div>
                        </div>
                        <div class="list_four" v-if="list.related_project_list.length==4" v-for="(item,index) in list.related_project_list" :key="index" @click="toProject(item.id)">
                            <div class="top">
                                <div class="topimg">
                                    <img :src="Baseurl+item.image" alt="">
                                </div>
                            </div>
                            <div class="span">{{item.title}}</div>
                            <div class="details">查看详情</div>
                        </div>
                    </div>
                </div>
                <div class="pro_there main" v-if="list.related_plan_list&&list.related_plan_list.length>0">
                    <div class="proth_top">
                        <div class="left_tit">相关案例</div>
                        <div class="more" @click="Plan_more">查看更多</div>
                    </div>
                    <div class="newscont">
                        <div class="anlist" v-for="(item,index) in list.related_plan_list" :key="index" @click="toPlan(item.id)">
                            <div class="topimg">
                                <img :src="Baseurl+item.image" alt="">
                            </div>
                            <div class="antext">
                                <span class="span">{{item.title}}</span>
                                <div class="xin">{{item.en_title}}</div>
                                <div class="details">查看详情</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pro_there main" v-if="list.related_case_list&&list.related_case_list.length>0">
                    <div class="proth_top">
                        <div class="left_tit">相关方案</div>
                        <div class="more" @click="Case_more">查看更多</div>
                    </div>
                    <div class="newscont">
                        <div class="anlist" v-for="(item,index) in list.related_case_list" :key="index" @click="toCase(item.id)">
                            <div class="fangan">解决方案</div>
                            <div class="topimg">
                                <img :src="Baseurl+item.image" alt="">
                            </div>
                            <div class="antext">
                                <span class="span">{{item.title}}</span>
                                <div class="xin">{{item.en_title}}</div>
                                <div class="details">查看详情</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <Footer />
    </div>
</template>

<script>
    import Header from "../owned/header.vue"
    import Footer from "../owned/footer.vue"
    import Swiper from "swiper";

    export default {
        components: {
            Header,
            Footer
        },
        data() {
            return {
                id: "",//二级模块ID
                module_id: '',//类型
                list:[],
                hoverIndex: 0
            };
        },

        created() {
            this.id = this.$route.query.id
            this.getinfo()
        },
        inject: ["reload"],
        methods: {
            getinfo() {
                var that = this;
                that.$axios
                .get(`${this.Baseurl}/top_search?id=${that.id}`)
                .then(res => {
                    console.log(res)
                    var arr =res.data.data[0]
                    that.list= res.data.data[0]
                    that.list.content = JSON.parse(that.list.content)
                    // console.log(that.list.content)
                    // console.log(JSON.parse(that.list.content))
                }).catch(err => console.log(err));
            },
            hover(index) {
                console.log(index)
                this.hoverIndex = index
            },
            //去产品详情页
            toProject(id) {
                //related_project
                this.$router.push({ name: 'Productdetails',query:{
                    id:id,
                    module_id:this.module_id,
                }})
                this.reload()
            },
            //去产品更多
            Project_more(){
                console.log(this.list.related_project_list)
                var nli = this.list.related_project_list[0].father[0]
                this.$router.push({name:nli.modul_id,query:{
                    id:nli.id,
                    module_id:nli.modul_id,
                }})
            },

            //去案例详情页
            toPlan(id) {
                this.$router.push({ name: 'Anlidetails',query:{
                    id:id,
                    module_id:this.module_id,
                }})
                this.reload()
            },
            //去案例更多
            Plan_more(){
                console.log(this.list.related_plan_list)
                var nli = this.list.related_plan_list[0].father[0]
                this.$router.push({name:"Anlilist",query:{
                    id:nli.id,
                    module_id:nli.modul_id,
                }})
            },

            //去方案详情页
            toCase(id) {
                this.$router.push({ name: 'Gramdetails',query:{
                    id:id,
                    module_id:this.module_id,
                }})
                this.reload()
            },
            //去方案更多
            Case_more(){
                console.log(this.list.related_case_list)
                var nli = this.list.related_case_list[0].father[0]
                this.$router.push({name:nli.modul_id,query:{
                    id:nli.id,
                    module_id:nli.modul_id,
                }})
            },
            
        },
        mounted() {

        },

    };
</script>
<style scoped>
    .newscont_bg {
        /*padding-top: 56px; */
        padding-bottom: 44px;
    }

    .pro_one .title {
        width: 100%;
        height: 85px;
        background-color: #F5F5F5;
    }

    .pro_one .title p {
        width: 1400px;
        margin: auto;
        text-align: center;
        line-height: 85px;
        font-size: 28px;
        color: #333333;
    }

    .enter {
        margin-top: 30px;
        padding-bottom: 50px;
    }

    .enter_nav {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    .enter_nav .enter_tit {
        width: 130px;
        height: 40px;
        background-color: #f7f7f7;
        border: 1px solid #d0d0d0;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        color: #101010;
        margin: 0 21px;
        cursor: pointer;
    }

    .enter_nav .enter_tit.active {
        background-color: #2d64c8;
        border: 1px solid #2d64c8;
        color: #fff;
    }

    .enter .enter_box {
        padding-bottom: 30px;
    }

    .enter>.enter_box>.enter_text {
        width: 100%;
    }

    .enter>.enter_box>.enter_text /deep/ p {
        padding: 0 20px;
        font-size: 18px;
        color: #101010;
        line-height: 28px;
        margin-bottom: 5px;
    }

    .enter>.enter_box>.enter_text /deep/ img {
        max-width: 100%;
        margin: auto;
    }

    .newscont {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
    }

    .left_tit {
        font-size: 28px;
        color: #434343;
        margin-bottom: 30px;
    }


    .pro_there {
        padding-top: 40px;
        border-top: 2px solid #eeeeee;
    }

    .proth_top {
        display: flex;
        align-items: center;
    }

    .more {
        margin-left: auto;
        font-size: 16px;
        text-decoration: underline;
        color: #434343;
        cursor: pointer;
        margin-bottom: 30px;
    }

    .more:hover {
        color: #2d64c8;
    }

    
</style>