var gettoken = function() {
    var that = this;
      console.log(this.Baseurl)
      that.$axios
        .post(`${this.Baseurl}/hotel_state_token`)
        .then(function (res) {
          console.log(res)
          sessionStorage.setItem("token", res.data.token);
        })
        .catch((err) => console.log(err));
}
export default gettoken;