<template>
    <div class="container">
        <!-- 头部 -->
        <Header />

        <div class="news">
            <div class="top_img">
                <img :src="Baseurl+list.banner_image" alt="">
            </div>
            <!-- 323 303 -->
            <!-- 300 300 -->
            <div class="public_title">
                <div class="pumain">
                    <div class="pubbiao">{{list.name}}</div>
                    <div class="pubright">
                        <img class="dian" src="../../assets/img/dian.png" alt="">
                        <span class="risp" v-if="list.father">{{list.father[0].name}}</span>
                        <img class="rispi" v-if="list.father" src="../../assets/img/rispi.png" alt="">
                        <span class="risp">{{list.name}}</span>
                    </div>
                </div>
            </div>
            <div class="newscont_bg">
                <div class="newscont main">
                    <div class="list" v-for="(item,index) in olists" :key="index"  @click="todetails(item.id)">
                        <div class="top">
                            <div class="topimg">
                                <img :src="Baseurl+item.image" alt="">
                            </div>
                        </div>
                        <div class="span">{{item.title}}</div>
                        <div class="details">查看详情</div>
                    </div>
                </div>
            </div>

        </div>

        <!-- 底部 -->
        <Footer />
    </div>
</template>

<script>
    import Header from "../owned/header.vue"
    import Footer from "../owned/footer.vue"

    export default {
        components: {
            Header,
            Footer
        },
        data() {
            return {
                id: "",//二级模块ID
                module_id: '',//类型
                list: [],//页面内容
                olists:[],
            };
        },

        created() {
            this.id = this.$route.query.id
            this.module_id = this.$route.query.module_id
            this.getinfo()
        },
        inject: ["reload"],
        methods: {
            getinfo() {
                var that = this;
                that.$axios
                    .get(`${this.Baseurl}/search_module?id=${that.id}&module_id=${that.module_id}`)
                    .then(res => {
                        // console.log(res)
                        that.list = res.data.data
                        console.log(that.list)
                        that.olists=res.data.data.news
                    }).catch(err => console.log(err));
            },
            //去产品详情页
            todetails(id) {
                this.$router.push({ name: 'Productdetails',query:{
                    id:id,
                    module_id:this.module_id,
                }})
            },

        },
        mounted() {

        },

    };
</script>
<style scoped>
    .newscont_bg {
        background-color: #f5f5f5;
        padding-top: 56px;
        padding-bottom: 44px;

    }

    .newscont {
        display: flex;
        flex-wrap: wrap;
    }

    .list {
        width: 320px;
        height: 400px;
        padding: 6px;
        cursor: pointer;
        box-sizing: border-box;
        background-color: #F5F5F6;
        box-shadow: 0px 0px 12px 4px #bfbfbf;
        margin-bottom: 27px;
        margin-left: 40px;
    }

    .list:nth-child(4n+1) {
        margin-left: 0;
    }

    .list .top {
        background-color: #fff;
        width: 100%;
        height: 270px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list .top .topimg {
        /* width: 249px;
    height: 160px; */
        width: 250px;
        height: 250px;
        display: block;
    }

    .list .top .topimg img {
        width: 100%;
        height: 100%;
        display: block;
        transition: all 0.3s;
    }

    .list .span {
        font-size: 18px;
        color: #434343;
        margin-top: 20px;
        text-align: center;
        padding: 0 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
    }

    .details {
        width: 108px;
        height: 40px;
        margin: auto;
        border: 1px solid #D0D0D0;
        text-align: center;
        font-size: 14px;
        color: #454545;
        line-height: 40px;
        cursor: pointer;
        background-color: #f7f7f7;
        margin-top: 15px;
    }

    /* .list:hover {
    box-shadow: 0px 0px 12px 4px #bfbfbf;
} */
    .list:hover .details {
        background-color: #2d64c8;
        border: 1px solid #2d64c8;
        color: #fff;
    }

    .list:hover .top .topimg img {
        transform: scale(1.1);
    }
</style>