<template>
    <div class="container">
      <!-- 头部 -->
      <Header />
  
      <div class="news">
        <div class="top_img">
            <img :src="Baseurl+list.banner_image" alt="">
        </div>
        <div class="public_title">
            <div class="pumain">
                <div class="pubbiao">{{list.name}}</div>
                <div class="pubright">
                    <img class="dian" src="../../assets/img/dian.png" alt="">
                    <span class="risp">{{list.name}}</span>
                </div>
            </div>
        </div>
        <div class="newscont_bg">
            <img class="nesbg" src="../../assets/img/contbg1.jpg" alt="">
            <div class="newscont main">
                <div class="list"  v-if="olists[0]">
                    <div class="list_top">
                        <img src="../../assets/img/con1.png" alt="">
                        <p>电话</p>
                    </div>
                    <div class="list_bot">
                        <div class="botp" v-html="olists[0].description"></div>
                    </div>
                </div>
                <div class="list"  v-if="olists[1]">
                    <div class="list_top">
                        <img src="../../assets/img/con2.png" alt="">
                        <p>网络</p>
                    </div>
                    <div class="list_bot">
                        <div class="botp" v-html="olists[1].description"></div>
                    </div>
                </div>
                <div class="list"  v-if="olists[2]">
                    <div class="list_top">
                        <img src="../../assets/img/con3.png" alt="">
                        <p>地址</p>
                    </div>
                    <div class="list_bot">
                        <div class="botp" v-html="olists[2].description"></div>
                    </div>
                </div>
            </div>
        </div>
        
      </div>
      
      <!-- 底部 -->
      <Footer/>
    </div>
</template>

<script>
import Header from "../owned/header.vue"
import Footer from "../owned/footer.vue"

export default {
components: {
    Header,
    Footer
},
data() {
    return {
        id: "",//二级模块ID
        module_id: '',//类型
        list: [],//页面内容
        olists:[],
    };
},

created() {
    this.id = this.$route.query.id
        this.module_id = this.$route.query.module_id
        this.getinfo()
    
},
methods: {
    getinfo() {
        var that = this;
        that.$axios
            .get(`${this.Baseurl}/search_module?id=${that.id}&module_id=${that.module_id}`)
            .then(res => {
                // console.log(res)
                that.list = res.data.data
                console.log(that.list)
                that.olists=res.data.data.news
            }).catch(err => console.log(err));
    },

},
mounted() {
    
},

};
</script>
<style scoped>
.newscont_bg{
    position: relative;
    padding-bottom: 125px;
}
.nesbg{
    position: absolute;
    left: 50%;
    margin-left: -960px;
    top: 0;
    width: 1920px;
    height: 229px;
    z-index: -1;
}
.newscont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.list{
    margin-top: 184px;
    width: 350px;
    position: relative;
}
.list::after{
    position: absolute;
    content: "";
    width: 2px;
    height: 184px;
    bottom: -30px;
    right: 0;
    background-color: #ececec;
    z-index: 1;
}
.list:last-child::after{
    display: none;
}

.list_top>img{
    width: 75px;
    height: 75px;
    display: block;
    margin: auto;
}
.list_top>p{
    font-size: 18px;
    color: #3577E8;
    margin-top: 19px;
    text-align: center;
}
.list_bot{
    width: 230px;
    margin: auto;
    margin-top: 50px;
}
.list:last-child{
    width: 530px;
}
.list:last-child .list_bot{
    width: 420px;
}
.list_bot .botp{
    font-size: 16px;
    color: #434343;
    line-height: 36px;
    display: flex;
}
.text{
    flex: 1;
}

</style>
