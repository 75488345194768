<template>
    <div class="container">
      <!-- 头部 -->
      <Header />
  
      <div class="news">
        <div class="top_img">
            <img :src="Baseurl+list.banner_image" alt="">
        </div>
        <div class="public_title">
            <div class="pumain">
                <div class="pubbiao">{{list.name}}</div>
                <div class="pubright">
                    <img class="dian" src="../../assets/img/dian.png" alt="">
                    <span class="risp" v-if="list.father">{{list.father[0].name}}</span>
                    <img class="rispi" v-if="list.father" src="../../assets/img/rispi.png" alt="">
                    <span class="risp">{{list.name}}</span>
                </div>
            </div>
        </div>
        <div class="down">
            <img class="downbg" src="../../assets/img/downbg.jpg" alt="">
            <div class="main">
                <div class="search">
                    <input type="text" name="" id="" placeholder="请输入关键词" v-model="sertxt">
                    <div class="sou" @click="search">
                        <img src="../../assets/img/search.png" alt="">
                        <p>搜索</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="newscont_bg">
            <div class="newscont main">
                <!-- @click="look(item.image)" -->
                <div class="list" v-for="(item,index) in olists" :key="index" @click="look(item.image)">
                    <div class="logo">
                        <img src="../../assets/img/dian.png" alt="">
                    </div>
                    <p>{{item.title}}</p>
                    <!-- @click="down(item.image)" -->
                    <!--  target="_blank" download :href="Baseurl+item.image" -->
                    <div class="xiazai" @click.stop="downPdf(Baseurl+item.image)">
                        <img class="xia1" src="../../assets/img/xia1.png" alt="">
                        <img class="xia2" src="../../assets/img/xia2.png" alt="">
                        <p >下载</p>
                    </div>
            </div>
        </div>
        </div>
       
      </div>
      
      <!-- 底部 -->
      <Footer/>
    </div>
</template>

<script>
import Header from "../owned/header.vue"
import Footer from "../owned/footer.vue"
import download from "downloadjs"

export default {
components: {
    Header,
    Footer
},
data() {
    return {
        id: "",//二级模块ID
        module_id: '',//类型
        list:[],//页面内容
        olists:[],
        sertxt:''
    };
},

created() {
    localStorage.setItem("navIndex",1)
    this.id = this.$route.query.id
    this.module_id = this.$route.query.module_id
    this.getinfo() 
},
methods: {
    downPdf(url){
        download(url)
    },

    fileLinkToStreamDownload(url) {
        // console.log(url)
        // return false;
      let fileName = this.getDay()
      let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/
      if (!reg.test(url)) {
        throw new Error('传入参数不合法,不是标准的文件链接')
      } else {
        let xhr = new XMLHttpRequest()
        xhr.open('get', url, true)
        xhr.setRequestHeader('Content-Type', `application/pdf`)
        xhr.responseType = 'blob'
        let that =this
        xhr.onload = function() {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response
            that.downloadExportFile(blob, fileName)
          }
        }
        xhr.send()
      }
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement('a')
      let href = blob
      if (typeof blob == 'string') {
        downloadElement.target = '_blank'
      } else {
        href = window.URL.createObjectURL(blob) //创建下载的链接
      }
      downloadElement.href = href
      downloadElement.download =
          tagFileName+
           //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      if (typeof blob != 'string') {
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }
    },
    getDay() {
        let time = new Date(),
        year = time.getFullYear(),
        month = time.getMonth() + 1,
        day = time.getDate(),
        timeStem = time.getTime()
        return `${year}/${month}/${day}/${timeStem}.jpg`
    },



      getinfo() {
        var that = this;
        that.$axios
          .get(`${this.Baseurl}/search_module?id=${that.id}&module_id=${that.module_id}`)
          .then(res => {
            console.log(res)
            that.list= res.data.data
            that.olists= res.data.data.news
          }).catch(err => console.log(err));
      },
      search(){
        var that = this;
        that.$axios
          .get(`${this.Baseurl}/search_module?id=${that.id}&module_id=${that.module_id}&keyword=${that.sertxt}`)
          .then(res => {
            // console.log(res)
            that.list= res.data.data
            that.olists= res.data.data.news
          }).catch(err => console.log(err));
      },
      look(url){
        console.log(this.Baseurl+url)
        window.open(this.Baseurl+url)
      },
      down(url){
        window.open(this.Baseurl+url)
        // window.location.href = this.Baseurl+url;
      }

},
mounted() {
    
},

};
</script>
<style scoped>
.down{
    width: 100%;
    height: 100px;
    position: relative;
    padding-top: 20px;
    box-sizing: border-box;
}
.down .downbg{
    position: absolute;
    width: 1920px;
    height: 100%;
    left: 50%;
    margin-left: -960px;
    top: 0;
    z-index: -1;
}
.search{
    width: 590px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #404040;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
}
.search input{
    border: none;
    width: 450px;
    height: 100%;
    font-size: 18px;
    color: #555;
    padding-left: 32px;
}
.search input::placeholder{
    color: #C4C4C4;
}
.sou{
    width: 100px;
    height: 100%;
    border-radius: 5px 0 0 5px;
    background-color: #555555;
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.sou>img{
    width: 20px;
    height: 20px;
    display: block;
    margin-left: 20px;
}
.sou>p{
    font-size: 16px;
    color: #C4C4C4;
    margin-left: 10px;
}
.newscont_bg{
    background-color: #f7f7f7; 
    padding-top: 12px;
    padding-bottom: 44px;
}
.newscont{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
}
.list{
    width: 681px;
    height: 90px;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-top: 29px;
    cursor: pointer;
}
.list .logo{
    width: 21px;
    height: 21px;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
}
.list .logo>img{
    width: 12px;
    height: 11px;
    display: block;
}
.list>p{
    width: 410px;
    font-size:18px;
    color: #454545;
    margin-left: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;

}
.xiazai{
    width: 100px;
    height: 40px;
    border: 1px solid #D0D0D0;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 44px;
    text-decoration: none;
}
.xiazai img{
    width: 15px;
    height: 15px;
    margin-left: 17px;
}
.xiazai .xia1{
    display: block;
}
.xiazai .xia2{
    display: none;
}
.xiazai p{
    font-size: 14px;
    color: #454545;
    margin-left: 14px;
}
.list:hover{
    box-shadow: 0px 0px 12px 4px #bfbfbf;
}
.list:hover .xiazai{
    
    background-color: #2d64c8;
    border: 1px solid #2d64c8;
}
.list:hover .xiazai p{
    color: #fff;
}
.list:hover .xiazai .xia1{
    display: none;
}
.list:hover .xiazai .xia2{
    display: block;
}
</style>
