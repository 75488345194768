<template>
    <div class="container">
      <!-- 头部 -->
      <Header />
  
      <div class="news">
        <div class="search main">
            <div class="search_top">
                <span>全站搜索：</span>
                <input type="text" name="" id="" placeholder="请输入要搜索的信息" v-model="searchtext">
                <div class="sousuo" @click="toSearch">
                    <img src="../../assets/img/sear.png" alt="">
                </div>
            </div>
            <div class="search_jieguo">
                搜索<span>{{keyword}}</span>共显示 {{list.length}} 个结果
            </div>
            <div class="search_cont">
                <div class="search_list" v-for="(item,index) in list" :key="index" @click="topath(item)">
                    <div class="span">{{item.title}}</div>
                    <div class="text">{{item.en_title}}</div>
                </div>
            </div>
        </div>
        
      </div>
      
      <!-- 底部 -->
      <Footer/>
    </div>
</template>

<script>
import Header from "../owned/header.vue"
import Footer from "../owned/footer.vue"

export default {
components: {
    Header,
    Footer
},
data() {
    return {
        keyword:'',//传递来的关键词
        searchtext:'',//搜索的关键词
        list:[],
    };
},

created() {
    //keyword
    this.keyword = this.$route.query.keyword
    console.log(this.keyword)
    this.getinfo()
},

inject: ["reload"],
methods: {
    topath(item){
        console.log(item)
        var modul_id = item.father[0].modul_id
        var aa = modul_id+'details'
        // if(modul_id=='News'){
            this.$router.push({name:aa,query:{
                id:item.id,
            }})
        // }
    },
    toSearch(){
      console.log(this.searchtext)
      this.$router.push({name:'Search',query:{
        keyword:this.searchtext
      }})
      this.reload()
    },

    getinfo() {
        var that = this;
        that.$axios
          .get(`${this.Baseurl}/top_search?keyword=${that.keyword}`)
          .then(res => {
            console.log(res)
            that.list= res.data.data
          }).catch(err => console.log(err));
    },
},
mounted() {
    
},

};
</script>
<style scoped>
    .search{
        padding-top: 33px;
    }
.search_top{
    width: 100%;
    height: 175px;
    display: flex;
    align-items: center;
    background-color: #f5f4f4;
    border-radius: 8px;
}
.search_top>span{
    font-size: 30px;
    color: #434343;
    margin-left: 60px;
}
.search_top>input{
    background-color: #fff;
    width: 600px;
    height: 60px;
    padding: 0 25px;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-size: 18px;
    color: #434343;
    margin-left: 22px;
}
.search_top .sousuo{
    width: 31px;
    height: 31px;
    margin-left: 22px;
    cursor: pointer;
}
.search_top .sousuo>img{
    width: 100%;
    height: 100%;
    display: block;
}
.search_jieguo{
    font-size: 24px;
    color: #434343;
    padding: 35px 30px 30px 30px;
}
.search_jieguo>span{
    color: #ee1d23;
}
.search_cont{
    border-top: 1px solid #b4b4b4;
    padding: 20px 30px;
}
.search_list{
    cursor: pointer;
    padding-top: 25px;
    padding-bottom: 30px;
}
.search_list .span{
    font-size: 22px;
    color: #454545;
    font-weight: bold;
    line-height: 30px;
}
.text{
    font-size: 18px;
    color: #454545;
    line-height: 30px;
}
.search_list:hover .span{
    color: #2d64c8;
}
</style>
